import { getCurrentUserId } from 'hc-core/composables/auth.js'
import { getLocationLabel } from 'hc-core/composables/misc.js'

export default {
  data () {
    return {
      entity: null,
      entityCompany: null,
      showEndStepProp: false,
    }
  },
  computed: {
    categoriesById () { return this.$store.getters['common/categoriesById'] },
    // Generating chip options array for some steps
    contractCategories () {
      const categories = []
      const offerCategoriesIds = this.$store.getters['common/offerCategoriesIds']
      if (offerCategoriesIds && offerCategoriesIds.length) {
        for (const catId of offerCategoriesIds) {
          if (this.categoriesById[catId]) {
            categories.push({
              label: this.categoriesById[catId].name,
              value: this.categoriesById[catId].id,
              needDuration: ['ctgy_J9Ap2E1VZy1kszSVVZy', 'ctgy_zC01TE1E821lhRR9E82', 'ctgy_2X0J7E1yoK1lMDoxyoK', 'ctgy_SOyXrE1YW11m1pRBYW1', 'ctgy_HMYe0E1WG01ktfVPWG0'].includes(catId)
            })
          }
        }
      }
      return categories
    },
    steps () {
      return [
        {
          icon: 'uil:pen',
          field: 'name',
          fields: ['name', 'customAttributes.jobReferential'],
          slug: 'name',
          label: 'offer.name',
          helpString: 'offer.form.name.help_string',
          hint: 'offer.form.name.hint',
          component: 'OfferTitle',
          getAll: false,
          readonly: !!(this.$_.get(this.entity, 'platformData.published', false) || this.$_.get(this.entity, 'platformData.archived', false)),
          dataLabel: 'jobs',
          optionLabel: 'jobboardLabel',
          formLabelField: 'name',
          formValueField: 'customAttributes.jobReferential',
        },
        {
          icon: 'uil:map-marker',
          field: 'locations',
          slug: 'location',
          label: 'offer.location',
          helpString: 'offer.form.location.help_string',
          component: 'OfferLocation',
          buildSnippet: this.$_.get(this.entity, 'locations.length', 1) === 1 ? 'text' : 'list',
          buildFn: (i) => {
            if (this.$_.get(this.entity, 'locations.length', 1) === 1) return getLocationLabel(this.$_.get(this.entity, 'locations[0]', null))
            else return this.$_.get(this.entity, 'locations', []).map(l => getLocationLabel(l))
          }
        },
        {
          icon: 'uil:file-edit-alt',
          field: 'categoryId',
          fields: ['categoryId', 'metadata._job.contractDuration'],
          slug: 'contract',
          label: 'offer.contractType',
          helpString: 'offer.form.contractType.help_string',
          component: 'OfferCategory',
          options: this.contractCategories,
          buildFn: (i) => {
            return i.categoryId
              ? `${this.$_.get(this.contractCategories.find(c => c.value === i.categoryId), 'label', null)}${this.$_.get(this.contractCategories.find(c => c.value === i.categoryId), 'needDuration', false)
                ? ` - ${this.$_.get(i, 'metadata._job.contractDuration', 'X')} mois`
                : ''}`
              : null
          },
        },
        {
          icon: 'uil:clock-three',
          field: 'customAttributes.jobSchedule',
          slug: 'rythm',
          label: 'offer.contractRythm',
          helpString: 'offer.form.contractRythm.help_string',
          component: 'ChipsPicker',
          options: this.getCustomAttributeValues('jobSchedule'),
          requestedType: 'String',
          buildString: 'customAttributes.jobSchedule.',
        },
        {
          icon: 'uil:home',
          field: 'customAttributes.remoteWork',
          slug: 'remote',
          label: 'offer.remoteWork',
          helpString: 'offer.form.remoteWork.help_string',
          component: 'ChipsPicker',
          optional: true,
          options: this.getCustomAttributeValues('remoteWork').filter(i => ['yes_1', 'yes_2', 'yes_3_more', 'no', 'partial', 'yes'].includes(i.value)),
          requestedType: 'String',
          defaultValue: 'no',
          buildString: 'customAttributes.remoteWork.',
        },
        {
          icon: 'uil:schedule',
          field: 'metadata._job.contractDate',
          slug: 'date',
          label: 'offer.contractDate',
          helpString: 'offer.form.contractDate.help_string',
          component: 'OfferDate',
          buildFn: (i) => {
            return i ? ['notSpecified', 'asap'].includes(i) ? this.$t({ id: `component.forms.offerForm.contractDate.${i}` }) : this.$t({ id: 'time.date' }, { fmtd: i }) : null
          },
        },
        {
          icon: 'uil:graduation-cap',
          field: 'customAttributes.formation',
          fields: ['customAttributes.formation', 'metadata._job.formation'],
          slug: 'formation',
          label: 'offer.formation',
          helpString: 'offer.form.formation.help_string',
          component: 'ChipsPicker',
          optional: true,
          options: this.getCustomAttributeValues('formation'),
          requestedType: 'Array',
          buildFnForce: true,
          buildFn: (i) => {
            const r = this.$_.concat(this.$_.map(i, s => this.$t({ id: `customAttributes.formation.${s}` })), this.$_.get(this.entity, 'metadata._job.formation', []))
            return this.$_.compact(r).length ? r.join(', ') : null
          },
        },
        {
          icon: 'uil:hourglass',
          field: 'customAttributes.experience',
          slug: 'experience',
          label: 'offer.experience',
          helpString: 'offer.form.experience.help_string',
          component: 'ChipsPicker',
          options: this.getCustomAttributeValues('experience'),
          requestedType: 'Array',
          buildFnForce: true,
          buildFn: (i) => {
            const r = this.$_.map(i, s => this.$t({ id: `customAttributes.experience.${s}` }))
            return this.$_.compact(r).length ? this.$_.compact(r).join(', ') : null
          },
        },
        {
          icon: 'uil:wrench',
          field: 'customAttributes',
          slug: 'tools',
          label: 'offer.tools',
          helpString: 'offer.form.tools.help_string',
          hint: 'offer.form.tools.hint',
          component: 'ToolsPicker',
          optional: true,
          buildSnippet: 'tools',
          buildFn: (i) => {
            const r = this.$_.union(
              this.$_.map(this.$_.get(this.entityCompany, 'customAttributes.tools', []), t => this.$t({ id: `customAttributes.tools.${t}` })),
              this.$_.get(this.entityCompany, 'customAttributes.tools_custom', '').split(' ').map(t => t.replaceAll('_', ' ')),
              this.$_.map(this.$_.get(i, 'tools', []), t => this.$t({ id: `customAttributes.tools.${t}` })),
              this.$_.get(i, 'tools_custom', '').split(' ').map(t => t.replaceAll('_', ' '))
            )
            return this.$_.compact(r).length ? this.$_.compact(r).sort() : null
          },
        },
        {
          icon: 'uil:smile-beam',
          field: 'customAttributes.softSkills',
          fields: ['customAttributes.softSkills', 'metadata._job.softSkills'],
          slug: 'soft-skills',
          label: 'offer.softSkills',
          helpString: 'offer.form.softSkills.help_string',
          component: 'ChipsPicker',
          chipsPickerExtended: true,
          chipsPickerAddLabel: 'Ajouter une soft-skill',
          options: this.getCustomAttributeValues('softSkills'),
          requestedType: 'Array',
          optional: true,
          buildFnForce: true,
          buildFn: (i) => {
            const r = this.$_.concat(this.$_.map(i, s => this.$t({ id: `customAttributes.softSkills.${s}` })), this.$_.get(this.entity, 'metadata._job.softSkills', []))
            return this.$_.compact(r).length ? this.$_.compact(r).join(', ') : null
          },
        },
        {
          icon: 'uil:euro-circle',
          field: 'customAttributes.salaryPeriod',
          slug: 'wage',
          label: 'offer.wage',
          helpString: 'offer.form.wage.help_string',
          component: 'OfferWage',
          buildFn: (i) => {
            if (this.$_.get(this.entity, 'metadata._job.salaryUndefined', false)) {
              return this.$t({ id: 'component.forms.offerForm.wage.undefined_label' })
            } else if (this.$_.get(this.entity, 'metadata._job.salaryType', null) && this.$_.get(this.entity, 'customAttributes.salaryPeriod', null) && (this.$_.get(this.entity, 'customAttributes.salaryMin', false) || this.$_.get(this.entity, 'customAttributes.salaryMax', false))) {
              return this.$t({ id: 'component.forms.offerForm.wage.offerString' }, {
                tax: this.$_.get(this.entity, 'metadata._job.salaryTax', null),
                type: this.$_.get(this.entity, 'metadata._job.salaryType', null),
                min: this.$_.get(this.entity, 'customAttributes.salaryMin', null),
                max: this.$_.get(this.entity, 'customAttributes.salaryMax', null),
                period: this.$_.get(this.entity, 'customAttributes.salaryPeriod', null),
              })
            } else return null
          },
        },
        {
          icon: 'uil:award',
          field: 'customAttributes.advantages',
          fields: ['customAttributes.advantages', 'metadata._job.advantages'],
          slug: 'advantages',
          label: 'offer.advantages',
          helpString: 'offer.form.advantages.help_string',
          hint: 'offer.form.advantages.hint',
          component: 'ChipsPicker',
          chipsPickerExtended: true,
          chipsPickerAddLabel: 'Ajouter un avantage',
          options: this.getCustomAttributeValues('advantages'),
          requestedType: 'Array',
          optional: true,
          buildSnippet: 'list',
          buildFnForce: true,
          buildFn: (i) => {
            const r = this.$_.concat(
              this.$_.map(i, s => this.$t({ id: `customAttributes.advantages.${s}` })),
              this.$_.get(this.entity, 'metadata._job.advantages', []),
              this.$_.get(this.entityCompany, 'metadata._team.advantages', []).map(a => a.text),
            )
            return this.$_.compact(r).length ? this.$_.compact(r) : null
          },
        },
        {
          icon: 'uil:clipboard-notes',
          field: 'description',
          slug: 'description',
          label: 'offer.description',
          helpString: 'offer.form.description.help_string',
          component: 'OfferDescription',
          maxChars: 3000,
          type: 'textarea',
          buildSnippet: 'html',
          buildFn: (i) => {
            return '<span><strong>À propos du poste</strong></span><br>'
              .concat(!this.$_.isNil(i) && i.length ? i : this.$t({ id: 'offer.description_unfilled' }))
              .concat(this.$_.get(this.entityCompany, 'description', false) ? `<br><br><span><strong>À propos de ${this.entityCompany.name}</strong></span><br>${this.entityCompany.description}` : '')
          }
        },
        {
          icon: 'uil:eye',
          field: 'metadata.multicast',
          slug: 'review',
          label: 'offer.review',
          component: 'OfferReview',
          optional: true,
          getAll: false,
          readonly: !!(this.$_.get(this.entity, 'platformData.published', false) || this.$_.get(this.entity, 'platformData.archived', false)),
          buildString: 'component.forms.offerForm.review.checkbox_',
        },
      ].map((s, i) => { return this.$_.set(s, 'index', i) })
    },

    formStructure () {
      return {
        endStep: {
          title: 'offer.form.endTitle',
          subtitle: 'offer.form.endSubtitle',
          image: 'thumbsUpMan',
          callbackData: {
            active: true,
            validated: true,
            platformData: { published: new Date().toISOString() }
          },
          buttons: [
            {
              color: 'accent',
              label: 'offer.form.seeOnline',
              route: this.$web({ name: 'companyOffer', params: { slug: this.$_.get(this.entityCompany, 'customAttributes.slug', null), assetSlug: this.$_.get(this, 'entity.customAttributes.slug', null) } }),
              target: '_blank'
            },
            {
              color: 'primary',
              label: 'layouts.drawer.offers',
              route: { name: 'offers' }
            }
          ]
        },

        defaultFields: [
          { path: 'assetTypeId', value: this.$store.getters['common/offerTypeId'] },
          { path: 'active', value: false },
          { path: 'validated', value: false },
        ],

        preview: {
          component: 'OfferBuild',
          props: {
            offer: this.entity,
            company: this.entityCompany,
          },
          type: 'offer',
          previewTitle: this.$t({ id: 'pages.offer_edit.previewTitle' }),
          quitButton: this.$t({ id: 'pages.offer_edit.quitButton' }),
          completeButton: this.$t({ id: 'pages.offer_edit.completeButton' }),
          defaultTitle: this.$t({ id: 'pages.offer_edit.defaultTitle' }),
          completeTitle: this.$t({ id: 'pages.offer_edit.completeTitle' }),
        },

        returnRoute: 'offers',
      }
    },

    tableProp () {
      return {
        // User inputs for filtering
        filters: {
          checkboxes: [
            {
              key: 'ownerId',
              label: this.$t({ id: 'pages.offers.see_all' }),
              default: this.$uElements('targetId'),
              'true-value': this.$uElements('targetId'),
              'false-value': this.$_.get(this.currentUser, 'id', null),
              if: this.isSubscribed()
            }
          ].filter(i => this.$_.get(i, 'if', true)),
          selects: [
            {
              key: 'status',
              label: 'Statut des offres',
              options: this.$_.concat(
                [
                  { label: `Tous les statuts - ${this.$uElements('stats.offers.total')}`, value: null },
                  { label: `${this.$t({ id: 'pages.offers.published' })} - ${this.$uElements('stats.offers.active')}`, value: { active: true, validated: true }, default: true },
                  { label: `${this.$t({ id: 'pages.offers.draft' })} - ${this.$uElements('stats.offers.draft')}`, value: { active: false, validated: false } },
                  { label: `${this.$t({ id: 'pages.offers.closed' })} - ${this.$uElements('stats.offers.archived')}`, value: { active: false, validated: true } },
                ],
              ),
              style: 'min-width: 180px;'
            },
          ].filter(i => this.$_.get(i, 'if', true)),
        },
        columns: [
          { name: 'name', align: 'left', label: this.$t({ id: 'component.tables.offer_table.col_offers' }), customOrderField: 'name' },
          { name: 'categoryId', align: 'left', label: this.$t({ id: 'component.tables.offer_table.col_contract' }), visible: this.$q.screen.gt.md, customOrderField: 'categoryId' },
          { name: 'location', align: 'left', label: this.$t({ id: 'component.tables.offer_table.col_place' }), visible: this.$q.screen.gt.sm, customOrderField: 'locations' },
          { name: 'published', align: 'left', label: this.$t({ id: 'component.tables.offer_table.col_release' }), visible: this.$q.screen.gt.md, customOrderField: 'platformData.published' }, // TODO : replace this one column by multiple based on status ?
          { name: 'applications', align: 'left', label: this.$t({ id: 'component.tables.offer_table.col_applicants' }), visible: this.$q.screen.gt.xs },
          { name: 'actions', align: 'right', label: this.$t({ id: 'component.tables.offer_table.col_actions' }) },
        ],
        defaultParams: {
          ownerId: this.$uElements('targetId'), // All orgs, set by checkbox now
        },
        defaultFilters: { // Applied at mount or on reset
          withApplicationsNb: true,
          customOrderConfig: {
            orderBy: 'createdDate',
            order: 'desc',
            nulls: 'last'
          },
        },
        refresh: async (params) => {
          if (params.status) {
            params = this.$_.omit(
              this.$_.merge(params, params.status),
              'status'
            )
          }

          return await this.$store.dispatch('asset/listOffers', {
            ...this.$_.pick(params, [
              'ownerId',
              'active',
              'validated',
              'page',
              'nbResultsPerPage',
              'customOrderConfig',
              'withApplicationsNb',
            ])
          })
        },
      }
    }
  },
  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      if (process.env.HC_PLATFORM !== 'MANAGER') {
        this.entity = this.$_.get(this, 'offer', this.$route.params.id ? await this.$store.dispatch('asset/read', { assetId: this.$route.params.id }) : null)
        this.entityCompany = this.$_.get(this, 'company', this.userCompany)
      }
    },
    // Form events
    async formEvent (evt) {
      try {
        if (evt.method) {
          switch (evt.method) {
            case 'saveStep':
              if (!this.$_.get(this.entity, 'id', false)) {
                this.entity = await this.$store.dispatch('asset/create', { attrs: evt.value })
                this.$router.replace({ name: 'offerForm', params: { id: this.entity.id } })
              } else {
                if (this.entity.ownerId && this.entity.ownerId.startsWith('org_') !== getCurrentUserId()) await this.$store.dispatch('auth/selectOrganization', { organizationId: this.entity.ownerId }, { root: true })
                // Adding slug
                if (!this.$_.get(this.entity, 'customAttributes.slug', false)) {
                  this.$_.set(evt.value, 'customAttributes.slug', await this.$store.dispatch('asset/slugifyAsset', { name: this.entity.name || evt.value.name }))
                }
                this.entity = await this.$store.dispatch('asset/update', { assetId: this.entity.id, attrs: evt.value })
              }
              break
            case 'callback':
              await this.formCallback(evt)
              break
            case 'setShowProps':
              this.$_.set(this, 'showEndStepProp', evt.value)
              break
            case 'refresh':
              await this.refresh()
              break
          }
        }
      } catch (e) { this.useLogger(e) }
    },
    async formCallback (evt) {
      try {
        if (Object.keys(evt.value).length) {
          await this.$store.dispatch('common/sendCustomEvent', {
            type: 'offer_published',
            objectId: this.entity.id,
            metadata: { republished: this.$_.get(this.entity, 'platformData.published', false) }
          })
          await this.$store.dispatch('asset/update', { assetId: this.entity.id, attrs: evt.value })

          // Use starter credit (asked by OfferReview)
          if (this.$_.get(evt.value, 'platformData.unrestrictedStarter', false) && !this.$_.get(this.entity, 'platformData.unlocked', false)) {
            await this.$store.dispatch('common/sendCustomEvent', {
              type: 'ask_applications_unrestrict',
              objectId: getCurrentUserId(),
              metadata: {
                assetId: this.entity.id,
                ownerId: this.$_.get(this.$store.getters['auth/currentUser'], 'id', null),
                useCredit: true,
                rootUserId: this.$uElements('rootUser').id,
              }
            })
          }

          // Check if related page is active
          if (!this.$_.get(this.userCompany, 'active')) {
            this.$q.dialog({
              title: 'Attention',
              message: 'La page actuellement liée à cette offre est inactive, votre offre ne sera donc pas visible dans la recherche par les candidats',
              ok: {
                label: 'Activer ma page',
                color: 'primary'
              },
              cancel: {
                color: 'negative',
                label: 'Ignorer'
              },
              persistent: true
            }).onOk(async () => {
              await this.$store.dispatch('asset/updateCompany', { asset: this.userCompany, attrs: { active: true, validated: true } })
              this.notifySuccess()
            })
          }
        }
        // Setting up some properties
        this.showEndStepProp = true
      } catch (e) { this.useLogger(e) }
    },
  }
}
