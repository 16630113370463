<template>
  <div
    id="IndeedPixel"
    ref="IndeedPixel"
  >
    <!--  Begin INDEED conversion code -->
    <noscript><img height=1 width=1 border=0 src="//conv.indeed.com/pagead/conv/5029708121164022/?script=0" alt="i"></noscript>
    <!-- End INDEED conversion code -->
  </div>
</template>

<script>
export default {
  name: 'IndeedPixel',
  mounted () {
    const scriptElement0 = document.createElement('script')
    scriptElement0.setAttribute('type', 'application/javascript')
    scriptElement0.text = 'var indeed_conversion_id = \'5029708121164022\'; var indeed_conversion_label = \'\';'
    document.getElementById('IndeedPixel').appendChild(scriptElement0)
    const scriptElement1 = document.createElement('script')
    scriptElement1.setAttribute('src', '//conv.indeed.com/applyconversion.js')
    document.getElementById('IndeedPixel').appendChild(scriptElement1)
  }
}
</script>
