<template>
  <div v-if="company && offer">
    <div
      v-if="!mini"
      class="q-my-md text-h4 text-weight-bold text-center"
    >
      {{ offer.name }}
    </div>
    <QList>
      <QItem
        v-for="(prop, i) in computedEntityBuilder"
        :key="i"
        dense
        class="q-px-none"
      >
        <QItemSection
          avatar
          top
          class="q-pa-none gt-xs"
        >
          <QAvatar
            :icon="prop.icon"
            size="lg"
            text-color="primary"
          />
        </QItemSection>
        <QItemSection>
          <div class="flex items-center q-gutter-xs">
            <div class="text-subtitle text-weight-bold">
              {{ $t({ id: prop.label }) }}&nbsp;:
            </div>
            <div v-if="prop.snippet === 'text' || (prop.snippet === 'tools' && $q.screen.lt.md)">
              {{ prop.snippet === 'tools' ? prop.value.join(', ') : prop.value }}
            </div>
          </div>
          <div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="prop.snippet === 'div'"
              v-html="prop.value"
            />
            <!-- eslint-enable vue/no-v-html -->
            <ul
              v-else-if="prop.snippet === 'list'"
              class="q-my-sm"
            >
              <li
                v-for="element of prop.value"
                :key="element"
              >
                {{ element }}
              </li>
            </ul>
            <ul
              v-else-if="prop.snippet === 'tools'"
              class="q-my-sm"
            >
              <template
                v-for="element of prop.value"
                :key="element"
              >
                <!-- CA tools, some formatting + link -->
                <li
                  v-if="toolsValues.find(t => t.label === element)"
                  class="cursor-pointer"
                  @click="openTool(toolsValues.find(t => t.label === element).value)"
                >
                  <span>{{ element }}</span>
                  <QIcon
                    class="text-primary q-ml-sm"
                    name="uil:external-link-alt"
                  />
                </li>
                <li v-else>
                  {{ element }}
                </li>
              </template>
            </ul>
            <div v-else-if="prop.snippet === 'multiple'">
              <div
                v-for="(item, j) of prop.value"
                :key="j"
              >
                {{ item.label }} :
                <span class="text-grey-6">
                  {{ item.value ? item.value : '-' }}
                </span>
              </div>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-else-if="prop.snippet === 'html'"
              class="text-body-2 text-weight-regular"
              v-html="prop.value"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </QItemSection>
      </QItem>
    </QList>
  </div>
</template>

<script>
import OfferMixins from 'hc-core/mixins/offer'
import BuilderMixins from 'hc-core/mixins/builder.js'

export default {
  mixins: [BuilderMixins, OfferMixins],
  props: {
    company: {
      type: Object,
      default: null,
    },
    offer: {
      type: Object,
      default: null,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toolsValues () { return this.getCustomAttributeValues('tools') },
    computedEntityBuilder () {
      // We use a computed so that not recomputed in display scrolling - better perf
      return this.entityBuilder({ entity: this.offer, getAll: false }).filter(p => {
        return this.mini ? ['location', 'contract', 'wage'].includes(p.slug) : true
      })
    }
  },
  methods: {
    openTool (name) {
      if (process.env.HC_PLATFORM === 'WEB') {
        window.open(this.$router.resolve({ name: 'knowledgeTools', params: { name } }).href, '_blank')
      } else {
        window.open(this.$web({ name: 'knowledgeTools', params: { name } }), '_blank')
      }
    },
  }
}
</script>
