import { isoToMask } from 'hc-core/composables/time.js'

export default {
  methods: {
    entityBuilder ({ entity = null, getAll = true }) {
      const output = []
      this.steps.filter(s => getAll ? true : this.$_.get(s, 'getAll', true)).forEach(step => {
        output.push({
          ...this.$_.pick(step, ['icon', 'slug', 'label']),
          stepIndex: step.index,
          snippet: step.buildSnippet ?? 'text',
          buildComponent: step.buildComponent ?? undefined,
          value: this.computedValue(entity, step)
        })
      })
      return output.filter(o => o.value !== null)
    },

    computedValue (entity, step) {
      try {
        // Complexes cases - MultipleItems
        if (step.component === 'MultipleItems') return step.multipleItemsData.filter(item => !this.$_.get(item, 'hideInBuild', false)).map(item => ({ label: this.$t({ id: item.label }), value: this.computedValue(entity, item), icon: item.icon ?? undefined, isLink: this.$_.get(item, 'isLink', undefined) })).filter(item => item.value)

        // General cases - ItemsArrayInput
        if (step.component === 'ItemsArrayInput') {
          return this.$_.get(entity, step.field, []).length ? this.$_.get(entity, step.field, []).map(i => {
            return typeof this.$_.get(step, 'buildFn', false) === 'function' ? step.buildFn(i) : i
          }) : null
        }

        // Specific case - OfferCategory OfferLocations (since still some hybrid work, multiple or unique locations)
        if (step.component === 'OfferCategory' || step.component === 'OfferLocation') {
          return step.buildFn(entity)
        }

        if (!this.$_.get(step, 'buildFnForce', false)) {
          // Complexes cases - Array steps that requires mapping on each item (Locations, Tools, Advantages)
          if (typeof this.$_.get(step, 'buildFn', false) === 'function' && Array.isArray(this.$_.get(entity, step.field, false))) return this.$_.get(entity, step.field, []).map(item => step.buildFn(item))

          // Same case but not for array items
          else if (typeof this.$_.get(step, 'buildFn', false) === 'function' && !Array.isArray(this.$_.get(entity, step.field, false))) return step.buildFn(this.$_.get(entity, step.field, ''))
        }

        // General cases - ImageCropperUploader
        if (step.component === 'ImageCropperUploader') return this.$_.get(entity, step.field, false) ? 'Saisi ✔️' : false

        // General cases - Select
        if (step.component === 'SelectInput') return this.$_.get(step.options.find(o => o.value === this.$_.get(entity, step.field, false)), 'label', false)

        // General cases - OptionsInput
        if (step.component === 'OptionsInput') {
          return this.$t({ id: this.$_.get(this.$_.get(step, 'options', []).find((o) => o.value === this.$_.get(entity, step.field, false)), 'label', '/') })
        }

        // General cases - ChipsPicker
        if (step.component === 'ChipsPicker') {
          if (step.requestedType === 'Array') {
            if (typeof this.$_.get(step, 'buildFn', false) === 'function') return step.buildFn(this.$_.get(entity, step.field, ''))
            const r = this.$_.intersectionWith(this.$_.get(step, 'options', []), this.$_.get(entity, step.field, false), (arrVal, othVal) => { return arrVal.value === othVal }).map(o => o.label)
            return r.length > 0 ? r : null
          } else if (step.requestedType === 'String') {
            if (typeof this.$_.get(step, 'buildFn', false) === 'function') return step.buildFn(this.$_.get(entity, step.field, ''))
            else return step.buildString && this.$_.get(entity, step.field, null) ? this.$t({ id: `${step.buildString}${this.$_.get(entity, step.field)}` }) : this.$_.get(entity, step.field, null)
          } else return null
        }

        // General cases - ISO
        if (step.component === 'ISOInput') return isoToMask(this.$_.get(entity, step.field, null), step.mask)

        // General cases - Checkbox
        if (step.component === 'CheckboxInput') return this.$t({ id: `prompt.binary_${this.$_.get(entity, step.field, false) ? 'yes' : 'no'}` })

        // General cases - Select
        if (step.component === 'PlacesAutocomplete') return this.$_.get(entity, `${step.field}.formated`, false)

        // General cases - String
        if (['boolean', 'string'].includes(typeof this.$_.get(entity, step.field, false))) {
          return step.buildString && this.$_.has(entity, step.field, null) ? this.$t({ id: `${step.buildString}${String(this.$_.get(entity, step.field))}` }) : this.$_.get(entity, step.field, null)
        }

        return null
      } catch (e) {
        return null
      }
    }

  }
}
