<template>
  <div ref="AppLogo">
    <div
      v-if="!miniLogo"
      class="flex items-center"
    >
      <img
        class="gt-md"
        :src="cdnImg('platform/branding/hc_x_vdc_label.svg', { quality: 100, lossless: true })"
        loading="lazy"
        alt="i"
        height="30"
      >
      <img
        class="lt-lg"
        :src="cdnImg('platform/branding/hc_x_vdc_mobile.svg', { quality: 100, lossless: true })"
        loading="lazy"
        alt="i"
        height="30"
      >
    </div>
    <div
      v-else
      class="flex items-center"
    >
      <img
        :src="cdnImg('platform/branding/hc_x_vdc_mobile.svg', { quality: 100, lossless: true })"
        loading="lazy"
        alt="i"
        height="30"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    miniLogo: {
      type: Boolean,
      default: false
    },
    miniWidth: {
      type: String,
      default: '40px'
    }
  },
}
</script>
