<template>
  <!-- Indeed Application Tag -->
  <div
    id="IndeedTagApplied"
    ref="IndeedTagApplied"
  />
  <!-- END Indeed Application Tag -->
</template>

<script>
export default {
  props: {
    jobId: {
      type: String,
      default: '12345'
    },
    applicationId: {
      type: String,
      default: 'xyz-987'
    }
  },
  mounted () {
    const scriptElement = document.createElement('script')
    scriptElement.setAttribute('src', 'https://convcdn.indeed.tech/ld-min.js')
    document.getElementById('IndeedTagApplied').appendChild(scriptElement)
    this.$nextTick(() => {
      window.indeed_q = window.indeed_q || []
      window.ict = window.ict || function () {
        window.indeed_q.push(arguments)
      }
      window.ict('init', 'TL0CELYVKY')
      // only call the below function if you have user consent in countries where GDPR applies
      window.ict('recordApply', {
        jobId: this.jobId,
        // testFire: true, // !process.env.NODE_ENV === 'production',
        applicantId: this.currentUser.id ?? undefined,
        applicationId: this.applicationId ?? undefined,
      })
    })
  }
}
</script>
