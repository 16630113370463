<template>
  <p ref="TalentPixel">
    <!-- <img src="https://neuvoo.ca/pixel.gif?action=conversion+apply&amp;source=happycab-fr" alt="i"> -->
    <img
      src="https://www.talent.com/tracker/img-pixel.php?tracker=happycab"
      alt="i"
    >
    .
  </p>
</template>

<script>
export default {
  name: 'TalentPixel'
}
</script>
