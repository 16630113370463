<template>
  <div
    ref="LazyYtVideo"
    :class="`fit lazy_video bg-black cursor-pointer ${classes}`"
    @click="playVideo = true"
  >
    <QVideo
      v-if="playVideo"
      :src="src"
      :ratio="ratio"
      fetchpriority="low"
      loading="lazy"
      referrerpolicy="no-referrer"
      :class="classes"
      title="LazyYtVideo"
    />
    <QImg
      v-else
      :src="`https://i3.ytimg.com/vi/${id}/hqdefault.jpg`"
      loading="lazy"
      :ratio="ratio"
      :class="classes"
      alt="LazyYtVideo"
    >
      <div
        class="absolute-full flex flex-center"
        style="background:unset"
      >
        <svg
          viewBox="0 0 68 48"
          width="20%"
          height="20%"
        >
          <defs>
            <svg:style type="text/css"><![CDATA[
            .lazy_btn_shape { fill: #212121; fill-opacity: 0.8; }
            .lazy_btn_icon { fill: #fff; }
            .lazy_video:hover .lazy_btn_shape { fill: red; fill-opacity: 1; }
            ]]></svg:style>
          </defs>
          <path
            class="lazy_btn_shape"
            d="M66.5 7.7c-.8-2.9-2.5-5.4-5.4-6.2C55.8.1 34 0 34 0S12.2.1 6.9 1.6c-3 .7-4.6 3.2-5.4 6.1a89.6 89.6 0 0 0 0 32.5c.8 3 2.5 5.5 5.4 6.3C12.2 47.9 34 48 34 48s21.8-.1 27.1-1.6c3-.7 4.6-3.2 5.4-6.1C68 35 68 24 68 24s0-11-1.5-16.3z"
          />
          <path
            class="lazy_btn_icon"
            d="M45 24L27 14v20"
          />
        </svg>
      </div>
    </QImg>
  </div>
</template>

<script>
// Inspired by https://github.com/andrewvasilchuk/vue-lazy-youtube-video
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    ratio: {
      type: Number,
      default: 16 / 9,
    },
    classes: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      playVideo: false,
      YOUTUBE_REGEX: /^https:\/\/www\.youtube(?:-nocookie)?\.com\/embed\/(.+?)(?:\?.*)?$/,
    }
  },
  computed: {
    id () { return this.$_.get(this.YOUTUBE_REGEX.exec(this.src), '[1]', null) },
  }
}
</script>
