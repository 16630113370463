<template>
  <div
    ref="CompanyLogo"
    class="hc-round shadow-1"
  >
    <QAvatar
      v-if="company"
      :class="contentClass"
      :size="size"
    >
      <img
        v-if="$_.get(company, 'metadata._files.logo', false)"
        loading="lazy"
        alt="i"
        :src="cdnImg(company.metadata._files.logo, { width: s, height: s })"
        style="background-color: white; object-fit:cover;"
        width="100%"
        height="100%"
      >
      <div
        v-else
        class="full-height full-width flex flex-center text-weight-bold text-grey-11"
        :style="getColor"
      >
        {{ company.name.substring(0, 1).toUpperCase() }}
      </div>
    </QAvatar>
    <QAvatar
      v-else
      :class="contentClass"
      :size="size"
    >
      <img
        loading="lazy"
        alt="i"
        :src="cdnImg($t({ id: 'images.hc_placeholder' }), { width: s, height: s })"
        style="background-color: white"
      >
    </QAvatar>
  </div>
</template>

<script>
import { bgGradient } from 'hc-core/composables/misc.js'

export default {
  props: {
    size: {
      type: String,
      default: '45px'
    },
    contentClass: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      default: null
    }
  },
  computed: {
    s () { return parseInt(this.size.replace('px', '')) },
    getColor () { return this.company ? bgGradient(this.company.id) : '' },
  }
}
</script>
