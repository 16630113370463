<template>
  <QNoSsr>
    <div class="map-container">
      <div
        :id="options.container"
        :style="`height:${height}px`"
      />
    </div>
  </QNoSsr>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  props: {
    pins: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: function () {
        return {
          container: 'mapId',
          center: [3, 46.27],
          zoom: 5,
          minZoom: 2, // same for zoom levels
          maxZoom: 15,
        }
      },
    },
    height: {
      type: Number,
      default: 400
    },
  },
  data () {
    return {
      map: null,
      markers: [],
    }
  },
  mounted () {
    if (!process.env.CLIENT || !mapboxgl) return
    mapboxgl.accessToken = process.env.MAPBOX_TOKEN
    this.$nextTick(() => {
      const map = new mapboxgl.Map({
        container: this.options.container,
        style: process.env.MAPBOX_STYLE,
        center: this.computedCenter(),
        zoom: this.options.zoom,
        minZoom: this.options.minZoom,
        maxZoom: this.options.maxZoom,
        scrollZoom: false,
        doubleClickZoom: false,
      })
      this.map = map
      if (this.pins.length > 0) {
        // Build markers
        const markers = []
        for (const location of this.pins) {
          const el = document.createElement('div')
          el.id = location.id
          el.className = 'marker hc-round'
          el.style.width = '50px'
          el.style.height = '50px'
          el.style.backgroundImage = `url(${this.cdnImg(this.$_.get(location, 'company.metadata._files.logo', this.$t({ id: 'images.hc_placeholder' })), { width: 50, height: 50, quality: 65 })})`
          const marker = new mapboxgl.Marker(el)
            .setLngLat([location.longitude, location.latitude])
            .setPopup(
              new mapboxgl.Popup({ closeButton: false, className: 'stl-map-search-popup' })
                .setHTML(`<div id="${location.id}" class="br-8">${location.name ? location.name : location.city}<br/>${location.formated}</div>`)
            )
            .addTo(this.map)
          markers.push(marker)
        }

        // Move maps to fit all markers - https://stackoverflow.com/a/71659589
        this.map.fitBounds([
          [Math.min(...this.pins.map((pin) => pin.longitude)), Math.min(...this.pins.map((pin) => pin.latitude))],
          [Math.max(...this.pins.map((pin) => pin.longitude)), Math.max(...this.pins.map((pin) => pin.latitude))],
        ], { padding: 50 })
      }
    })
  },
  unmounted () {
    if (this.map) this.map.remove()
  },
  methods: {
    computedCenter () {
      if (!this.pins.length) return this.options.center
      // Compute - https://stackoverflow.com/a/42234774
      let X = 0.0
      let Y = 0.0
      let Z = 0.0
      for (let i = 0; i < this.pins.length; i++) {
        const lat = this.pins[i].latitude * Math.PI / 180
        const lon = this.pins[i].longitude * Math.PI / 180
        X += Math.cos(lat) * Math.cos(lon)
        Y += Math.cos(lat) * Math.sin(lon)
        Z += Math.sin(lat)
      }
      X /= this.pins.length
      Y /= this.pins.length
      Z /= this.pins.length
      const lon = Math.atan2(Y, X)
      const hyp = Math.sqrt(X * X + Y * Y)
      const lat = Math.atan2(Z, hyp)
      return [(lon * 180 / Math.PI), (lat * 180 / Math.PI)]
    }
  }
}
</script>

<style lang="sass" scoped>
.marker
  background-size: cover
  width: 50px
  height: 50px
  border-radius: 50%
  cursor: pointer

.mapboxgl-popup-content
  background-color: $accent
  color: $dark
</style>
