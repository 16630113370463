<template>
  <div class="slider q-my-xl">
    <div class="slide-track">
      <div
        v-for="(avatar, i) of easyRecruitClients"
        :key="i"
        class="slide"
      >
        <QAvatar
          size="80px"
          class="hc-shadow"
          style="border-radius:40px; margin-left:5px; margin-right:5px;"
        >
          <img :src="cdnImg(avatar, { width: 80, height: 80 })">
        </QAvatar>
      </div>
    </div>
  </div>
</template>

<script>
const rawClients = [
  'assets/logos/1643970894_22tuGcI2G.png', // Activance
  'assets/logos/logo_ast_3CRYeOE1QA51r5uEYQA5.png', // AEd expert
  'assets/logos/logo_ast_ApzPbVE1SCK1qgMpFSCK.png', // Alliance experts
  'assets/logos/1683630451332_zoh2ljdHV.png', // Ambicia Conseils
  // '', // Anaxa expertise Audit et conseil - PAS DE LOGO
  'assets/logos/1677492605500_kDE9-cglW.png', // Atemi Finance Conseil
  'assets/logos/1652711982_s2bN7O2Eq.png', // Audrex
  'assets/logos/1601458650556-510a7066-75de-4c62-8c78-daebd543dca9.jpg', // Axiome associés
  'assets/logos/logo_ast_crZtMCE151Z1qESZu51Z.png', // Bizouard et associés
  'assets/logos/1656774949_12ZdQMQUa.png', // BLOOMEA EXPERT
  'assets/logos/1655970509___zvbmaOt.png', // C2C Conseils
  'assets/logos/1673973637791_HMdeiGVql.png', // Cabinet ARRIJURIA
  'assets/logos/logo_ast_gelvwKE1bvt1rRiiDbvt.png', // cabinet balagué expertise
  'assets/logos/1645025204_80p%24qWKrx.png', // Cabinet CR (Conseil)
  'assets/logos/logo_ast_9uKSZXE1eQI1mkGM6eQI.png', // Cabinet Follet BOUTIN
  'assets/logos/1617209648_J1p%24gzkK1.png', // Cabinet Mosselmans
  // '', // Cabinet Serge Mirouse - PAS DE LOGO
  'assets/logos/1613988654_%24l3re9EDE.jpg', // Cabinet Sofar
  'assets/logos/1627303455_WdZVUME4X.png', // Capec
  'assets/logos/1610032285_UfpvjPmqT.jpg', // CAPEOS CONSEILS
  'assets/logos/1601458660751-6136e9b9-627d-4cca-9f8b-b57448efbe69.jpg', // CECCA
  'assets/logos/logo_ast_Gl6dx6E1p2M1moNLEp2M.png', // CERALP -
  // '', // Conseil et Expertise Rhône Alpes - INTROUVABLE
  'assets/logos/1678455513126_CCuIu3Wt1.png', // Cerfrance Drôme Vaucluse
  'assets/logos/1646210349_Tshx%40t3vh.png', // Cinq Plus
  'assets/logos/logo_ast_JctmScE1JRw1qRqsCJRw.png', // COGEFI Expertise comptable
  'assets/logos/1646930456_Igb8Mgd%24b.png', // Cogera Expertise
  'assets/logos/1621259089_xzEJpHRft.png', // Cogesten
  'assets/logos/1644919073_L7GwGqueF.png', // ComptaFrance
  'assets/logos/1634895285_4DZAj1voV.png', // Conseil Expertise projets
  'assets/logos/1686044385501_nJdRiNKNa.png', // CTA
  'assets/logos/1601449764191-54f28436-d72d-47ae-b8da-5c110bf111b9.jpg', // ECCENTIVE
  'assets/logos/1676544086723_d9DGLtqve.png', // ECOCE
  'assets/logos/1679501498528_GUDAqh4n2.png', // Endrix
  'assets/logos/1651764335_vw1DE625A.png', // Euraudit OI - suppliers Pennylane
  'assets/logos/1675153314971_1RwEY_eFv.png', // Eurex
  'assets/logos/1644572865_Rj2aqwWJh.png', // Experts & Entreprendre
  'assets/logos/logo_ast_arNEeLE1KgQ1qWEBMKgQ.png', // FIADEX
  'assets/logos/1651762418_wAZ17p6ZC.png', // FICAMEX
  'assets/logos/1606725373_dpzrSVC4Z.png', // FIDAQUITAINE
  'assets/logos/1676384710064_HEhxqjWDk.png', // Figures groupe
  'assets/logos/logo_ast_fzpAL8E10SF1qqxIZ0SF.png', // Financea - NAGC
  'assets/logos/1601457988745-d3e0e01e-ca57-4210-9455-b7aec6113765.jpg', // FINTEK
  'assets/logos/1615824655_Cdp6LOC3K.png', // Firex GROUP
  'assets/logos/1674639496616_skqPChW-d.png', // FLG Caducial
  'assets/logos/1601458630258-6b643f37-765d-4400-b753-975000f97444.jpg', // Giornal
  'assets/logos/1601458009778-19c80c06-551d-47b6-8927-f1e38d647203.jpg', // GMBA
  'assets/logos/1642764294_88%24h58o9t.png', // Groupe DRB
  'assets/logos/1676456338994_QAyHxcfAL.png', // Groupe Legrand
  'assets/logos/logo__ast_EmsGDfE1oiN1qxhkCoiN.png', // Hive
  'assets/logos/1684389426375_59nZTzzXb.png', // Idec
  'assets/logos/1651761105_UJGCOTaVV.png', // Jégard Créatis
  'assets/logos/1624523960_k%40Ejnhz6K.png', // JHP Walter France
  'assets/logos/logo_ast_5yF0XhE1U7x1qgPuOU7x.png', // Lacaze & associés
  'assets/logos/logo_ast_9BCXh8E1FPh1qoDHXFPh.png', // Legati 26
  'assets/logos/1638440855_5SFUoQfy%40.png', // LEMPEREUR & ASSOCIES
  'assets/logos/1674665252646_varvlnLa-.png', // MYL AUDIT & CONSEILS
  'assets/logos/1601449082715-0d8646ec-ea1c-4412-b9d9-71b6de3c91d1.jpg', // NAOLINK
  'assets/logos/1627306558_pWNsl6g9Q.png', // Naviseo
  'assets/logos/1652711468_H8qtZ9OMK.png', // Numbr
  'assets/logos/1617652273_YCvaNx4B7.png', // Per'forma - expertise
  // '', // PETRUS (GROUPE FIDU) - INTROUVABLE
  'assets/logos/1621428461_jTDutGM%40M.png', // RSM
  'assets/logos/1610101414_obEyLJdTt.jpg', // S&R Paris (SRA-Paris)
  'assets/logos/1630503934_tUWR1ENGx.png', // Sadec Akelys - Impulsion
  'assets/logos/1652711722_lI%40GEOxQX.png', // Secab
  'assets/logos/1619517514_enlMy1O%40x.png', // SOCIC - mensuel - SOBEEZ
  'assets/logos/1638957036_xc2648sQj.png', // Sodecal
  'assets/logos/1674641072343_yrINZLWTD.png', // Sofico Conseil -
  'assets/logos/1623844843_ePgJyLoN%24.png', // SOFICO BRETAGNE SARL
  'assets/logos/1634737528_7qH3YinM0.png', // Soficom Walter France
  'assets/logos/1650355982_BVWUU3rC2.png', // SOGECMA
  'assets/logos/1680079270377_daLG207Gg.png', // SORECO
  'assets/logos/1622213072_V%243avKn2B.png', // Wity
]

export default {
  computed: {
    // should add 2 times the same content
    easyRecruitClients () { return this.$_.concat(rawClients, rawClients) },
  }
}
</script>

<style scoped>
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100px * 70)); /* half nb items in easyRecruitClients - avatar = 80 + 10 margin */
  }
}
.slider {
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 90%;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, rgb(247,247,252) 0%, rgba(247,247,252, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 60px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 120s linear infinite;
  display: flex;
  width: calc(100px * 140); /* nb items in easyRecruitClients */
}
.slider .slide {
  height: 80px;
  width: 100px;
}
</style>
